.qr-list td{
    text-align: left;
}

.table-box{
    text-align: center;
    margin: 10px;
    overflow: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    max-height: 650px !important;
}

.qr-list .center-title{
    margin: 0 !important;
}

.qr-list .circle-loader {
    margin-top: 50px !important;
}

.status{
    font-weight: 500;
}

.status option:nth-child(1), .PENDIENTE, .PENDING{
    color: #d2a500;
    font-weight: 500;

}

.status option:nth-child(2), .ACTIVO{
    color: green !important;
    font-weight: 500;

}

.status option:nth-child(3), .DESACTIVADO{
    color: red;
    font-weight: 500;
}

.search-box{
    width: 100%;
    border-radius: 10px;
    padding-left: 15px;
    margin: 30px 0px;
}

.table-result{
    td{
        padding-top: 15px !important;
    }

    &-id{
        max-width: 200px;
        word-wrap:break-word
    }
    &-url{
        max-width: 300px;
        word-wrap:break-word;
    }
    &-status{
        max-width: 120px;
    }

    &-edit{
        cursor: pointer;
        width: 18px;
        height: 18px;
        left: 5px;
        top: 5px;
        position: relative;
    }

    &-input{
        width: 70% !important;
        border-radius: 5px;
        padding-left: 10px;
    }

    &-cancel{
        cursor: pointer;
        width: 18px;
        height: 18px;
        left: 15px;
        top: 5px;
        position: relative;
    }

    &-checkmark{
        cursor: pointer;
        width: 18px;
        height: 18px;
        left: 30px;
        top: 5px;
        position: relative;
    }

    .circle-loader{
        position: absolute !important;
        width: 1em !important;
        height: 1em !important; 
        margin-top: 17px !important;
        margin-left: 20px;
    }
}