#generatorCanvas {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%,-50%);
}

#generatorCanvas .button-card{
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    background-color: #f7fafc !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    text-align: center;
} 

#generatorCanvas .center-title{
    text-align: center;
    margin: 10px;
}

#generatorCanvas .center-title h1{
    font-size: 18px;
}

#generatorCanvas .button-card{
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    background-color: #f7fafc !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    text-align: center;
    cursor: pointer;
} 


#generatorCanvas .button-card:hover{
    transition-duration: 0.5s;
    transform: translateY(-10px); 
}

#generatorCanvas .button-card svg{
    width: 100px;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
}

#generatorCanvas .button-card h2{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.form-control-label{
    font-size: 14px;
}

label{
    padding-top: 15px;
    padding-right: 7px;
    font-size: 14px;
    font-weight: bold;
}

.back-button-card{
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
}

.back-top-card{
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    left: 10px;
}

.back-top-card svg{
    width: 32px;
    height: 32px;
    margin-right: 5px;
}

.box-padding{
    padding: 5px;
}

.fade{
    opacity: 0.5 !important;
    z-index: 1;
}


#generatorCanvas{
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    padding: 50px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    background-color: #f7fafc !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.brand-logo{
    bottom: 5% !important;
    left: 50% !important;
    width: 80%;
    transform: translate(-50%,-60%) !important;
    width: 250px;
    position: absolute;
}

.button-log-out{
    position: absolute;
    bottom: 10px;
    right: 48%;
    cursor: pointer;
}

.button-back{
    float: left;
    color: #0010ce;
    z-index: 99;
    cursor: pointer;
    position: absolute;
    top: 8px;
}