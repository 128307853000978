#selectStat {
  margin-top: 50px;
}

.click-counter {
  margin-top: 40px;
  text-align: left;
  color: #fff;
  height: 200px;
}

.click-counter .click-counter-info-1 {
  padding: 40px;
  background-color: #5c84bf;
  border-radius: 5px;
}

.click-counter .click-counter-info-2 {
  padding: 40px;
  background-color: #6f9e39;
  border-radius: 5px;
}

.click-counter .click-counter-info-3 {
  padding: 40px;
  margin-top: 3px !important;
  background-color: #e05454;
  margin-bottom: 20px;
  border-radius: 5px;
}

.click-counter .click-counter-info-4 {
  padding: 40px;
  margin-top: 3px !important;
  background-color: #d89027;
  margin-bottom: 20px;
  border-radius: 5px;
}

.click-counter:nth-child(3) {
  margin-top: 0 !important;
  margin-bottom: 40px;
}

.click-counter:nth-child(4) {
  margin-top: 0 !important;
  margin-bottom: 40px;
}

.click-counter span:nth-child(1) {
  width: 100%;
  display: flex;
  font-size: 32px !important;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.click-counter span:nth-child(2) {
  font-size: 20px;
  display: flex;
  font-weight: 500;
  margin-top: 20px !important;
  max-height: 30px;
}

.click-counter span:nth-child(3) {
  font-size: 16px;
  display: flex;
  font-weight: 500;
  max-height: 30px;
}

.click-counter .middle-data {
  font-size: 25px;
  margin-bottom: 10px;
  font-size: 25px !important;
}

.click-counter .simple {
  margin-top: 30px;
}

.chart {
  text-align: left;
}

.chart-month {
  padding: 5px;
  color: #fff;
}

.chart-month-data {
  background-color: #85b2f5;
  color: #fff;
  border-radius: 5px;
}

.chart-title {
  margin-top: 0px !important;
  margin-bottom: 30px;
}

.chart-month h4 {
  color: #fff;
  margin-top: 20px !important;
}

.chart-general{
    margin-top: 30px !important;
    margin-bottom: 0 !important;
}