.form-label {
  display: flex;
  text-align: center;
}

.form-group {
  display: inline-flex;
}

.input-color {
  min-height: 10px !important;
  margin-top: 15px;
  min-height: 20px;
}

.form-label span {
  margin-top: 15px;
}

h4 {
  text-align: center;
  margin-top: 40px;
}

.confirm-card-button {
  text-align: center !important;
  margin-left: 20px;
}

.confirm-card {
  margin-top: 20px;
  text-align: center;
}

// Define vars we'll be using
$brand-success: #5cb85c;
$brand-error: red;
$loader-size: 3em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;
$check-color-error: $brand-error;
.circle-loader {
  margin-bottom: $loader-size/2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

.logo-edit-area {
  margin-top: -8px;
  padding: 20px;
  border-radius: 20px;
}

.logo-previsual-area {
  margin-bottom: 40px;
}
