#load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#load img {
  max-width: 250px;
  opacity: 1;
}

// Humo mayor
#load img path:nth-child(2) {
}

// Humo menor
#load img path:nth-child(4) {
}

// Peperoni 1
#load img path:nth-child(3) {
  opacity: 0;
  animation: fadeInOpacity 3s infinite;
  animation-delay: 0s;
}

// Peperoni 2
#load img path:nth-child(5) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 0.5s;
}

// Peperoni 3
#load img path:nth-child(6) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 0.7s;
}

// Peperoni 4
#load img path:nth-child(7) {
  opacity: 0;

  animation: fadeInOpacity 3s infinite;
  animation-delay: 1s;
}

.logo-loader {
  width: 250px;
}

.loader-mask {
  animation: scanning 0.9s infinite alternate-reverse;
}

@keyframes scanning {
  100% { transform: translate(0, 145px); }
}