#login {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translate(-50%,-50%);
}

#login small, #login .small{
    font-size: 14px;
}

#login .card-header {
    text-align: center;
}

#login .logo {
    max-width: 180px;
}

#login .full-logo {
    max-width: 500px;
    margin: 30px 0;
}

#login h1 {
    margin-top: 20px;
    margin-bottom: 10px;
}

#login h2 {
    color: rgb(124, 124, 124);
    font-weight: 300;
    font-size: 16px;
}

#login input::-webkit-input-placeholder {
    font-size: 14px;
}

#login input::-moz-placeholder {
    font-size: 14px;
}


#login button{
    width: 100%;
    padding: 12px 0;
    font-size: 14px;
    background-color: #2e5bc1;
    border: 1px solid #3d5690;
}

.error{
    text-align: center;
    color: red;
    font-size: 14px;
    font-weight: bold;
}

  .loadingspinner {
	pointer-events: none;
	width: 1.5em;
	height: 1.5em;
	border: 0.4em solid transparent;
	border-color: #eee;
	border-top-color: #3E67EC;
	border-radius: 50%;
    animation: loadingspin 1s linear infinite;
    margin: auto;

}

@keyframes loadingspin {
	100% {
			transform: rotate(360deg)
	}
}

.form-group{
    width: 100% !important;
}

.form-group .input-group{
    width: 100% !important;
}

.form-group .input-group:first-child{
    margin-top: 20px;
}